import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          "DashBoard": "DashBoard",
          "Deafult": "Deafult",
          "Ecommerce": "Ecommerce",
          "Widgets": "Widgets",
          "General": "General",
          "Chart": "Chart",
          "Project": "Project",
          "Project List": "Project List",
          "Create New": "Create New",
          "Product": "Product",
          "Product Page": "Product Page",
          "Product List": "Product List",
          "Add Product": "Add Product",
          "Payment Detail": "Payment Detail",
          "Order History": "Order History",
          "Pricing": "Pricing",
          "Invoice": "Invoice",
          "Cart": "Cart",
          "Checkout": "Checkout",
          "Whishlist": "Whishlist",
          "Users": "Users",
          "Users Profile": "Users Profile",
          "Users Edit": "Users Edit",
          "Users Cards": "Users Cards",
          "Calender": "Calender",
          "Chat": "Chat",
          "Chat App": "Chat App",
          "Video App": "Video App",
          "Email": "Email",
          "Mail Inbox": "Mail Inbox",
          "Read Mail": "Read Mail",
          "Compose": "Compose",
          "File Manager": "File Manager",
          "Kanban Board": "Kanban Board",
          "Bookmark": "Bookmark",
          "Task": "Task",
          "Social App": "Social App",
          "Contacts": "Contacts",
          "To-Do": "To-Do",
          "Ui-Kits": "Ui-Kits",
          "Typography": "Typography",
          "Avatars": "Avatars",
          "Helper-Classes": "Helper-Classes",
          "Grid": "Grid",
          "Tag & Pills": "Tag & Pills",
          "Progress": "Progress",
          "Modal": "Modal",
          "Alert": "Alert",
          "Popover": "Popover",
          "Tooltip": "Tooltip",
          "Spinners": "Spinners",
          "Dropdown": "Dropdown",
          "Accordion": "Accordion",
          "Tabs": "Tabs",
          "Bootstrap Tabs": "Bootstrap Tabs",
          "Line Tabs": "Line Tabs",
          "Shadow": "Shadow",
          "List": "List",
          "Bonus Ui": "Bonus Ui",
          "Scrollable": "Scrollable",
          "Tree": "Tree",
          "Rating": "Rating",
          "Dropzone": "Dropzone",
          "Tour": "Tour",
          "SweetAlert": "SweetAlert",
          "Ribbons": "Ribbons",
          "Pagination": "Pagination",
          "Breadcrumb": "Breadcrumb",
          "Range Slider": "Range Slider",
          "Image Cropper": "Image Cropper",
          "Sticky": "Sticky",
          "Drag and Drop": "Drag and Drop",
          "Upload": "Upload",
          "Basic Card": "Basic Card",
          "Draggable Card": "Draggable Card",
          "Timeline": "Timeline",
          "Icons": "Icons",
          "Flag Icon": "Flag Icon",
          "Fontawesome Icon": "Fontawesome Icon",
          "Ico Icon": "Ico Icon",
          "Themify Icon": "Themify Icon",
          "Feather Icon": "Feather Icon",
          "Whether Icon": "Whether Icon",
          "Buttons": "Buttons",
          "Default Style": "Default Style",
          "Button Group": "Button Group",
          "Charts": "Charts",
          "Apex Chart": "Apex Chart",
          "Google Chart": "Google Chart",
          "Chartjs": "Chartjs",
          "Chartist": "Chartist",
          "Forms": "Forms",
          "Form Controls": "Form Controls",
          "Form Validation": "Form Validation",
          "Basic Input": "Basic Input",
          "Checkbox & Radio": "Checkbox & Radio",
          "Input Groups": "Input Groups",
          "Mega Option": "Mega Option",
          "Form Widgets": "Form Widgets",
          "Datepicker": "Datepicker",
          "Typeahead": "Typeahead",
          "rangepicker": "rangepicker",
          "Touchspin": "Touchspin",
          "Select2": "Select2",
          "Switch": "Switch",
          "Clipboard": "Clipboard",
          "Form Default": "Form Default",
          "Form Wizard": "Form Wizard",
          "Tables": "Tables",
          "Reactstrap Table": "Reactstrap Table",
          "Basic Table": "Basic Table",
          "Sizing Table": "Sizing Table",
          "Border Table": "Border Table",
          "Styling Table": "Styling Table",
          "Data Tables": "Data Tables",
          "Sample Page": "Sample Page",
          "Others": "Others",
          "Coming Soon": "Coming Soon",
          "Coming Sample": "Coming Sample",
          "Coming with Bg-video": "Coming with Bg-video",
          "Coming with Bg-Image": "Coming with Bg-Image",
          "Authentication": "Authentication",
          "Login Simple": "Login Simple",
          "Login With bg image": "Login With bg image",
          "Login with image two": "Login with image two",
          "Login with validation": "Login with validation",
          "Login with tooltip": "Login with tooltip",
          "Login with sweetaleart": "Login with sweetaleart",
          "Register Simple": "Register Simple",
          "Register with Bg image": "Register with Bg image",
          "Register with Bg Video": "Register with Bg Video",
          "Unlock User": "Unlock User",
          "Forgot Password": "Forgot Password",
          "Create Passward": "Create Passward",
          "Maintenance": "Maintenance",
          "Gallery": "Gallery",
          "Gallery Grid": "Gallery Grid",
          "Gallery Grid  Desc": "Gallery Grid  Desc",
          "Masonry Gallery": "Masonry Gallery",
          "Masonry With Desc": " Masonry With Desc",
          "Hover Effect": "Hover Effect",
          "Blog": "Blog",
          "Blog Details": "Blog Details",
          "Blog Single": "Blog Single",
          "Add Post": "Add Post",
          "Job Search": "Job Search",
          "Cards View": "Cards View",
          "List View": "List View",
          "Job Details": "Job Details",
          "Apply": "Apply",
          "Learning": "Learning",
          "Learning List": "Learning List",
          "Detail Course": "Detail Course",
          "Maps": "Maps",
          "Google Maps": "Google Maps",
          "Editor": "Editor",
          "CK  Editor": "CK Editor",
          "MDE Editor": "MDE Editor",
          "ACE code editor": "ACE code editor",
          "FAQ": "FAQ",
          "knowledgebase": "knowledgebase",
          "KnowledgeCategory": "KnowledgeCategory",
          "KnowledgeDetail": "KnowledgeDetail",
          "sub": "sub",
        },
      },
      cn: {
        translations: {
            "DashBoard": "仪表板",
            "Deafult": "默认",
            "Ecommerce": "电子商务",
            "Widgets": "小部件",
            "General": "一般的",
            "Chart": "图表",
            "Project": "项目",
            "Project List": "项目清单",
            "Create New": "创建新的",
            "Product": "产品",
            "Product Page": "产品页面",
            "Product List": "产品列表",
            "Add Product": "添加产品",
            "Payment Detail": "付款明细",
            "Order History": "订单历史",
            "Pricing": "价钱",
            "Invoice": "发票",
            "Cart": "大车",
            "Checkout": "查看",
            "Whishlist": "心愿单",
            "Users": "用户",
            "Users Profile": "用户资料",
            "Users Edit": "用户编辑",
            "Users Cards": "用户卡",
            "Calender": "日历",
            "Chat": "聊天",
            "Chat App": "聊天应用",
            "Video App": "视频应用",
            "Email": "电子邮件",
            "Mail Inbox": "邮件收件箱",
            "Read Mail": "阅读邮件",
            "Compose": "撰写",
            "File Manager": "文件管理器",
            "Kanban Board": "看板",
            "Bookmark": "书签",
            "Task": "任务",
            "Social App": "社交应用",
            "Contacts": "联系人",
            "To-Do": "去做",
            "Ui-Kits": "媚俗",
            "Typography": "排版",
            "Avatars": "化身",
            "Helper-Classes": "辅助类",
            "Grid": "网格",
            "Tag & Pills": "标签和药丸",
            "Progress": "进步",
            "Modal": "模态",
            "Alert": "警报",
            "Popover": "弹窗",
            "Tooltip": "工具提示",
            "Spinners": "纺车",
            "Dropdown": "落下",
            "Accordion": "手风琴",
            "Tabs": "选项卡",
            "Bootstrap Tabs": "引导标签",
            "Line Tabs": "行选项卡",
            "Shadow": "阴影",
            "List": "列表",
            "Bonus Ui": "奖金用户界面",
            "Scrollable": "可滚动",
            "Tree": "树",
            "Rating": "评分",
            "Dropzone": "拖放区",
            "Tour": "旅游",
            "SweetAlert": "甜蜜提醒",
            "Ribbons": "丝带",
            "Pagination": "分页",
            "Breadcrumb": "面包屑",
            "Range Slider": "范围滑块",
            "Image Cropper": "图像裁剪器",
            "Sticky": "黏",
            "Drag and Drop": "拖放",
            "Upload": "上传",
            "Basic Card": "基本卡",
            "Draggable Card": "可拖动卡片",
            "Timeline": "时间线",
            "Icons": "图标",
            "Flag Icon": "国旗图标",
            "Fontawesome Icon": "字体真棒 图标",
            "Ico Icon": "图标",
            "Themify Icon": "主题化图标",
            "Feather Icon": "羽毛图标",
            "Whether Icon": "是否图标",
            "Buttons": "纽扣",
            "Default Style": "默认样式",
            "Button Group": "按钮组",
            "Charts": "图表",
            "Apex Chart": "顶点图表",
            "Google Chart": "谷歌图表",
            "Chartjs": "图表",
            "Chartist": "宪章主义者",
            "Forms": "形式",
            "Form Controls": "表单控件",
            "Form Validation": "表单验证",
            "Basic Input": "基本输入",
            "Checkbox & Radio": "复选框和收音机",
            "Input Groups": "输入组",
            "Mega Option": "超级选项",
            "Form Widgets": "表单小部件",
            "Datepicker": "日期选择器",
            "Typeahead": "提前输入",
            "rangepicker": "测距仪",
            "Touchspin": "触旋",
            "Select2": "选择2",
            "Switch": "转变",
            "Clipboard": "剪贴板",
            "Form Default": "表单默认",
            "Form Wizard": "表单向导",
            "Tables": "表",
            "Reactstrap Table": "反应表",
            "Basic Table": "基本表",
            "Sizing Table": "尺码表",
            "Border Table": "边框表",
            "Styling Table": "造型台",
            "Data Tables": "数据表",
            "Sample Page": "示例页面",
            "Others": "其他的",
            "Coming Soon": "即将推出",
            "Coming Sample": "来样",
            "Coming with Bg-video": "附带背景视频",
            "Coming with Bg-Image": "附带 背景图片",
            "Authentication": "验证",
            "Login Simple": "登录简单",
            "Login With bg image": "使用背景图片登录",
            "Login with image two": "图二登录",
            "Login with validation": "登录验证",
            "Login with tooltip": "使用工具提示登录",
            "Login with sweetaleart": "使用甜心登录",
            "Register Simple": "注册简单",
            "Register with Bg image": "使用背景图片注册",
            "Register with Bg Video": "注册后台视频",
            "Unlock User": "解锁用户",
            "Forgot Password": "忘记密码",
            "Create Passward": "创建密码",
            "Maintenance": "维护",
            "Gallery": "画廊",
            "Gallery Grid": "画廊网格",
            "Gallery Grid  Desc": "画廊网格描述",
            "Masonry Gallery": "砌体画廊",
            "Masonry With Desc": "带描述的砌体 ",
            "Hover Effect": "悬停效果",
            "Blog": "博客",
            "Blog Details": "博客详情",
            "Blog Single": "博客单",
            "Add Post": "添加帖子",
            "Job Search": "求职",
            "Cards View": "卡片视图",
            "List View": "列表显示",
            "Job Details": "职位详情",
            "Apply": "申请",
            "Learning": "学习",
            "Learning List": "学习清单",
            "Detail Course": "详细课程",
            "Maps": "地图",
            "Google Maps": "谷歌地图",
            "Editor": "编辑",
            "CK  Editor": "CK编辑器",
            "MDE Editor": "MDE 编辑器",
            "ACE code editor": "ACE编辑器",
            "FAQ": "常问问题",
            "knowledgebase": "知识库",
            "KnowledgeCategory": "知识类",
            "KnowledgeDetail": "知识详情",
            "sub": "子",
          },
      },
      ae: {
        translations: {
          "DashBoard": "لوحة القيادة",
          "Deafult": "تقصير",
          "Ecommerce": "Ecommerce",
          "Widgets": "الحاجيات",
          "General": "عام",
          "Chart": "جدول",
          "Project": "مشروع",
          "Project List": "قائمة المشروع",
          "Create New": "خلق جديد إبداع جديد",
          "Product": "منتج",
          "Product Page": "صفحة المنتج",
          "Product List": "قائمة المنتجات",
          "Add Product": "أضف منتج",
          "Payment Detail": "تفاصيل الدفع",
          "Order History": "تاريخ الطلب",
          "Pricing": "التسعير",
          "Invoice": "فاتورة",
          "Cart": "عربة التسوق",
          "Checkout": "الدفع",
          "Whishlist": "قائمة الرغبات",
          "Users": "المستخدمون",
          "Users Profile": "ملف تعريف المستخدمين",
          "Users Edit": "تحرير المستخدمين",
          "Users Cards": "بطاقات المستخدمين",
          "Calender": "تقويم",
          "Chat": "محادثة",
          "Chat App": "تطبيق الدردشة",
          "Video App": "تطبيق فيديو",
          "Email": "بريد إلكتروني",
          "Mail Inbox": "صندوق البريد الوارد",
          "Read Mail": "اقرأ البريد",
          "Compose": "مؤلف موسيقى",
          "File Manager": "مدير الملفات",
          "Kanban Board": "مجلس كانبان",
          "Bookmark": "المرجعية",
          "Task": "مهمة",
          "Social App": "التطبيق الاجتماعي",
          "Contacts": "جهات الاتصال",
          "To-Do": "لكى يفعل",
          "Ui-Kits": "مجموعات واجهة المستخدم",
          "Typography": "الطباعة",
          "Avatars": "الآلهة",
          "Helper-Classes": "فصول المساعد",
          "Grid": "شبكة",
          "Tag & Pills": "العلامة والحبوب",
          "Progress": "تقدم",
          "Modal": "مشروط",
          "Alert": "يُحذًِر",
          "Popover": "البوب",
          "Tooltip": "تلميح",
          "Spinners": "الغزالون",
          "Dropdown": "اسقاط",
          "Accordion": "الأكورديون",
          "Tabs": "نوافذ التبويب",
          "Bootstrap Tabs": "علامات تبويب التمهيد",
          "Line Tabs": "علامات تبويب الخط",
          "Shadow": "ظل",
          "List": "قائمة",
          "Bonus Ui": "مكافأة واجهة المستخدم",
          "Scrollable": "للتمرير",
          "Tree": "شجرة",
          "Rating": "تقييم",
          "Dropzone": "دروبزون",
          "Tour": "رحلة",
          "SweetAlert": "سويت أليرت",
          "Ribbons": "شرائط",
          "Pagination": "ترقيم الصفحات",
          "Breadcrumb": "مسار التنقل",
          "Range Slider": "نطاق المنزلق",
          "Image Cropper": "صورة كروبر",
          "Sticky": "لزج",
          "Drag and Drop": "السحب والإفلات",
          "Upload": "رفع",
          "Basic Card": "البطاقة الأساسية",
          "Draggable Card": "بطاقة قابلة للسحب",
          "Timeline": "الجدول الزمني",
          "Icons": "الأيقونات",
          "Flag Icon": "أيقونة العلم",
          "Fontawesome Icon": "رمز الخط رائع",
          "Ico Icon": "أيقونة إيكو",
          "Themify Icon": "أيقونةهم   ",
          "Feather Icon": "أيقونة الريشة",
          "Whether Icon": "أيقونة الطقس",
          "Buttons": "أزرار",
          "Default Style": "أسلوب إفتراضي",
          "Button Group": "مجموعة الأزرار",
          "Charts": "الرسوم البيانية",
          "Apex Chart": "أبكسشارتس",
          "Google Chart": "مخطط جوجل ",
          "Chartjs": "تشارتجس",
          "Chartist": "الجارتي",
          "Forms": "نماذج",
          "Form Controls": "ضوابط النموذج",
          "Form Validation": "التحقق من صحة النموذج",
          "Basic Input": "المدخلات الأساسية",
          "Checkbox & Radio": "خانة الاختيار والراديو",
          "Input Groups": "مجموعات الإدخال",
          "Mega Option": "خيار ميجا",
          "Form Widgets": "الحاجيات النموذج",
          "Datepicker": "منتقي البيانات",
          "Typeahead": "اكتب للأمام",
          "rangepicker": "منتقي المدى",
          "Touchspin": "لمسة تدور",
          "Select2": "حدد 2",
          "Switch": "يُحوّل",
          "Clipboard": "الحافظة",
          "Form Default": "نموذج افتراضي",
          "Form Wizard": "معالج النماذج",
          "Tables": "الجداول",
          "Reactstrap Table": "يتفاعل جدول فخ",
          "Basic Table": "الجدول الأساسي",
          "Sizing Table": "جدول التحجيم",
          "Border Table": "جدول الحدود",
          "Styling Table": "جدول التصميم",
          "Data Tables": "جداول البيانات",
          "Sample Page": "نموذج الصفحة",
          "Others": "آحرون",
          "Coming Soon": "قريباً",
          "Coming Sample": "عينة قادمة",
          "Coming with Bg-video": "يأتي مع فيديو الخلفية",
          "Coming with Bg-Image": "يأتي مع الصورة",
          "Authentication": "المصادقة",
          "Login Simple": "تسجيل الدخول بسيط",
          "Login With bg image": "تسجيل الدخول مع صورة bg",
          "Login with image two": "تسجيل الدخول بالصورة الثانية",
          "Login with validation": "تسجيل الدخول مع التحقق",
          "Login with tooltip": "تسجيل الدخول مع تلميح الأداة",
          "Login with sweetaleart": "تسجيل الدخول مع سويتالارت",
          "Register Simple": "تسجيل بسيط",
          "Register with Bg image": "سجل بالصورة",
          "Register with Bg Video": "سجل بالفيديو",
          "Unlock User": "فتح المستخدم",
          "Forgot Password": "هل نسيت كلمة السر",
          "Create Passward": "أنشئ كلمة مرور",
          "Maintenance": "صيانة",
          "Gallery": "صالة عرض",
          "Gallery Grid": "معرض الشبكة",
          "Gallery Grid  Desc": "معرض الشبكة",
          "Masonry Gallery": "معرض البناء",
          "Masonry With Desc": "الماسونية مع تنازلي",
          "Hover Effect": "تأثير التحويم",
          "Blog": "مدونة",
          "Blog Details": "تفاصيل المدونة",
          "Blog Single": "مدونة واحدة",
          "Add Post": "أضف منشورا",
          "Job Search": "البحث عن الوظائف",
          "Cards View": "عرض البطاقات",
          "List View": "عرض القائمة",
          "Job Details": "تفاصيل الوظيفة",
          "Apply": "يتقدم",
          "Learning": "Learning",
          "Learning List": "قائمة التعلم",
          "Detail Course": "دورة تفصيلية",
          "Maps": "خرائط",
          "Google Maps": "خرائط جوجل",
          "Editor": "محرر",
          "CK  Editor": "محرر CK",
          "MDE Editor": "محرر MDE",
          "ACE code editor": "محرر كود ACE",
          "FAQ": "التعليمات",
          "knowledgebase": "قاعدة المعرفة",
          "KnowledgeCategory": "فئة المعرفة",
          "KnowledgeDetail": "المعرفة بالتفصيل",
          "sub": "الفرعية",
        },
      },
      du: {
        translations: {
          "DashBoard": "Dashboard",
          "Deafult": "Standaard",
          "Ecommerce": "Ecommerce",
          "Widgets": "Widgets",
          "General": "Algemeen",
          "Chart": "Grafiek",
          "Project": "projecteren",
          "Project List": "Project List",
          "Create New": "Maak nieuw",
          "Product": "Product",
          "Product Page": "Productpagina",
          "Product List": "Product lijst",
          "Add Product": "Product toevoegen",
          "Payment Detail": "Betalings detail",
          "Order History": "Bestelgeschiedenis",
          "Pricing": "Prijzen",
          "Invoice": "Factuur",
          "Cart": "Winkelwagen",
          "Checkout": "Uitchecken",
          "Whishlist": "Verlanglijst",
          "Users": "Gebruikers",
          "Users Profile": "Gebruikers profiel",
          "Users Edit": "Gebruikers bewerken",
          "Users Cards": "Gebruikerskaarten",
          "Calender": "Kalender",
          "Chat": "Chatten",
          "Chat App": "Chat App",
          "Video App": "Video App",
          "Email": "E-mail",
          "Mail Inbox": "Postvak IN",
          "Read Mail": "Lees mail",
          "Compose": "Componeren",
          "File Manager": "Bestandsbeheer",
          "Kanban Board": "Kanban-bord",
          "Bookmark": "Bladwijzer",
          "Task": "taak",
          "Social App": "Sociale app",
          "Contacts": "Contacten",
          "To-Do": "Te doen",
          "Ui-Kits": "Ui-Kits",
          "Typography": "Typografie",
          "Avatars": "Avatars",
          "Helper-Classes": "Helper-klassen",
          "Grid": "Rooster",
          "Tag & Pills": "Label & pillen",
          "Progress": "Voortgang",
          "Modal": "Modaal",
          "Alert": "Alarm",
          "Popover": "Pop-over",
          "Tooltip": "Knopinfo",
          "Spinners": "Spinners",
          "Dropdown": "Laten vallen",
          "Accordion": "Accordeon",
          "Tabs": "Tabbladen",
          "Bootstrap Tabs": "Bootstrap-tabbladen",
          "Line Tabs": "Lijn tabbladen",
          "Shadow": "Schaduw",
          "List": "Lijst",
          "Bonus Ui": "Bonus-UI",
          "Scrollable": "Schuifbaar",
          "Tree": "Boom",
          "Rating": "Beoordeling",
          "Dropzone": "Afgeefpunt",
          "Tour": "Tour",
          "SweetAlert": "SweetAlert",
          "Ribbons": "Linten",
          "Pagination": "Paginering",
          "Breadcrumb": "Paneermeel",
          "Range Slider": "Bereik schuifregelaar",
          "Image Cropper": "Afbeelding bijsnijden",
          "Sticky": "Kleverig",
          "Drag and Drop": "Drag and Drop",
          "Upload": "Uploaden",
          "Basic Card": "Basiskaart",
          "Draggable Card": "Versleepbare kaart",
          "Timeline": "Tijdlijn",
          "Icons": "Pictogrammen",
          "Flag Icon": "Vlagpictogram",
          "Fontawesome Icon": "Fontawesome Icon",
          "Ico Icon": "Ico-pictogram",
          "Themify Icon": "Themify-pictogram",
          "Feather Icon": "Veer pictogram",
          "Whether Icon": "Of het nu icoon is",
          "Buttons": "Toetsen",
          "Default Style": "Standaard stijl",
          "Button Group": "Knop Groep",
          "Charts": "Grafieken",
          "Apex Chart": "Apex-grafiek",
          "Google Chart": "Google-grafiek",
          "Chartjs": "Chartjs",
          "Chartist": "Chartist",
          "Forms": "Formulieren",
          "Form Controls": "Formulier controles",
          "Form Validation": "Formulier validatie",
          "Basic Input": "Basis invoer",
          "Checkbox & Radio": "Selectievakje & Radio",
          "Input Groups": "Invoer groepen",
          "Mega Option": "Mega-optie",
          "Form Widgets": "Formulier Widgets",
          "Datepicker": "Datumkiezer",
          "Typeahead": "Typ vooruit",
          "rangepicker": "bereikkiezer",
          "Touchspin": "Touchspin",
          "Select2": "Selecteer2",
          "Switch": "Schakelaar",
          "Clipboard": "Klembord",
          "Form Default": "Formulier standaard",
          "Form Wizard": "Formulier Wizard",
          "Tables": "Tafels",
          "Reactstrap Table": "Reactstrap-tabel",
          "Basic Table": "Basis tabel",
          "Sizing Table": "Maattabel",
          "Border Table": "Grenstafel",
          "Styling Table": "Stylingtafel",
          "Data Tables": "Gegevenstabellen",
          "Sample Page": "voorbeeld pagina",
          "Others": "Anderen",
          "Coming Soon": "Binnenkort beschikbaar",
          "Coming Sample": "Komend monster",
          "Coming with Bg-video": "Komt met Bg-video",
          "Coming with Bg-Image": "Komt met Bg-Image",
          "Authentication": "Authenticatie",
          "Login Simple": "Inloggen Eenvoudig",
          "Login With bg image": "Inloggen Met bg afbeelding",
          "Login with image two": "Log in met afbeelding twee",
          "Login with validation": "Inloggen met validatie",
          "Login with tooltip": "Inloggen met knopinfo",
          "Login with sweetaleart": "Inloggen met sweetaleart",
          "Register Simple": "Registreer eenvoudig",
          "Register with Bg image": "Registreer met Bg afbeelding",
          "Register with Bg Video": "Schrijf je in bij Bg Video",
          "Unlock User": "Gebruiker ontgrendelen",
          "Forgot Password": "Wachtwoord vergeten",
          "Create Passward": "Paswacht maken",
          "Maintenance": "Onderhoud",
          "Gallery": "Galerij",
          "Gallery Grid": "Galerij raster",
          "Gallery Grid  Desc": "Galerij Grid Besch",
          "Masonry Gallery": "Metselwerk Galerij",
          "Masonry With Desc": "Metselwerk Met Besch",
          "Hover Effect": "Zweefeffect",
          "Blog": "bloggen",
          "Blog Details": "Blogdetails",
          "Blog Single": "Blogsingle",
          "Add Post": "Bericht toevoegen",
          "Job Search": "Zoek naar werk",
          "Cards View": "Kaarten bekijken",
          "List View": "Lijstweergave",
          "Job Details": "Details van de baan",
          "Apply": "Toepassen",
          "Learning": "Aan het leren",
          "Learning List": "Leren lijst",
          "Detail Course": "Gedetailleerde cursus",
          "Maps": "Kaarten",
          "Google Maps": "Google Kaarten",
          "Editor": "Editor",
          "CK  Editor": "CK  Editor",
          "MDE Editor": "MDE Editor",
          "ACE code editor": "ACE code editor",
          "FAQ": "FAQ",
          "knowledgebase": "kennis basis",
          "KnowledgeCategory": "Kenniscategorie",
          "KnowledgeDetail": "KennisDetail",
          "sub": "sub",
        },
      },
      es: {
        translations: {
          "DashBoard": "Panel",
          "Deafult": "sordo",
          "Ecommerce": "Comercio electrónico",
          "Widgets": "Widgets",
          "General": "General",
          "Chart": "Cuadro",
          "Project": "Proyecto",
          "Project List": "Lista de proyectos",
          "Create New": "Crear nuevo",
          "Product": "Producto",
          "Product Page": "Página del producto",
          "Product List": "Lista de productos",
          "Add Product": "Agregar producto",
          "Payment Detail": "Detalles de pago",
          "Order History": "Historial de pedidos",
          "Pricing": "Precios",
          "Invoice": "Factura",
          "Cart": "Carro",
          "Checkout": "Verificar",
          "Whishlist": "Lista de deseos",
          "Users": "Usuarios",
          "Users Profile": "Perfil de Usuario",
          "Users Edit": "Usuarios Editar",
          "Users Cards": "Tarjetas de Usuario",
          "Calender": "Calendario",
          "Chat": "Charlar",
          "Chat App": "Aplicación de chat",
          "Video App": "Aplicación de vídeo",
          "Email": "Correo electrónico",
          "Mail Inbox": "Bandeja de entrada de correo",
          "Read Mail": "Leer el correo",
          "Compose": "Redactar",
          "File Manager": "Administrador de archivos",
          "Kanban Board": "Tablero Kanban",
          "Bookmark": "Marcador",
          "Task": "Tarea",
          "Social App": "Aplicación Social",
          "Contacts": "Contactos",
          "To-Do": "Hacer",
          "Ui-Kits": "Kits de interfaz de usuario",
          "Typography": "Tipografía",
          "Avatars": "Avatares",
          "Helper-Classes": "Clases de ayuda",
          "Grid": "Red",
          "Tag & Pills": "Etiqueta y píldoras",
          "Progress": "Progreso",
          "Modal": "Modales",
          "Alert": "Alerta",
          "Popover": "Popover",
          "Tooltip": "Información sobre herramientas",
          "Spinners": "Hilanderos",
          "Dropdown": "Desplegable",
          "Accordion": "Acordeón",
          "Tabs": "Pestañas",
          "Bootstrap Tabs": "Pestañas de Bootstrap",
          "Line Tabs": "Fichas de línea",
          "Shadow": "Sombra",
          "List": "Lista",
          "Bonus Ui": "Interfaz de usuario adicional",
          "Scrollable": "Desplazable",
          "Tree": "Árbol",
          "Rating": "Clasificación",
          "Dropzone": "Zona de descenso",
          "Tour": "Recorrido",
          "SweetAlert": "Sweet Alert",
          "Ribbons": "Cintas",
          "Pagination": "Paginación",
          "Breadcrumb": "Breadcrumb",
          "Range Slider": "Control deslizante de rango",
          "Image Cropper": "Recortador de imágenes",
          "Sticky": "Pegajoso",
          "Drag and Drop": "Arrastrar y soltar",
          "Upload": "Subir",
          "Basic Card": "Tarjeta Básica",
          "Draggable Card": "Tarjeta arrastrable",
          "Timeline": "Línea de tiempo",
          "Icons": "Iconos",
          "Flag Icon": "Icono de bandera",
          "Fontawesome Icon": "Icono de Fontawesome ",
          "Ico Icon": "Icono de Ico",
          "Themify Icon": "Icono de Themify",
          "Feather Icon": "Icono de pluma",
          "Whether Icon": "Icono de si",
          "Buttons": "Botones",
          "Default Style": "Estilo por Defecto",
          "Button Group": "Grupo de botones",
          "Charts": "Gráficos",
          "Apex Chart": "Gráfico de vértice",
          "Google Chart": "Gráfico de Google",
          "Chartjs": "Gráficojs",
          "Chartist": "Cartista",
          "Forms": "Formularios",
          "Form Controls": "Controles de formulario",
          "Form Validation": "Controles de formulario",
          "Basic Input": "Entrada básica",
          "Checkbox & Radio": "Casilla de verificación y radio",
          "Input Groups": "Grupos de entrada",
          "Mega Option": "Opción Mega",
          "Form Widgets": "Widgets de formulario",
          "Datepicker": "Selector de fechas",
          "Typeahead": "Escribir anticipadamente",
          "rangepicker": "seleccionador de rango",
          "Touchspin": "Toque giratorio",
          "Select2": "Seleccionar2",
          "Switch": "Cambiar",
          "Clipboard": "Portapapeles",
          "Form Default": "Predeterminado de formulario",
          "Form Wizard": "Asistente de formularios",
          "Tables": "Mesas",
          "Reactstrap Table": "Tabla de correa de reacción",
          "Basic Table": "Tabla Básica",
          "Sizing Table": "Tabla de tallas",
          "Border Table": "Tabla de borde",
          "Styling Table": "Mesa de estilo",
          "Data Tables": "Tablas de datos",
          "Sample Page": "Página de Ejemplo",
          "Others": "Otros",
          "Coming Soon": "Muy pronto",
          "Coming Sample": "Próxima muestra",
          "Coming with Bg-video": "Viene con Bg-video",
          "Coming with Bg-Image": "Viene con Bg-Imagen ",
          "Authentication": "Autenticación",
          "Login Simple": "Iniciar sesión simple",
          "Login With bg image": "Iniciar sesión con imagen bg  ",
          "Login with image two": "niciar sesión con la imagen dos",
          "Login with validation": "Iniciar sesión con validación",
          "Login with tooltip": "Iniciar sesión con información sobre herramientas",
          "Login with sweetaleart": "Iniciar sesión con sweetaleart",
          "Register Simple": "Registrar Simple",
          "Register with Bg image": "Registrarse con imagen Bg",
          "Register with Bg Video": "Registrarse con Bg Video",
          "Unlock User": "Desbloquear usuario",
          "Forgot Password": "Has olvidado tu contraseña",
          "Create Passward": "Crear Contraseña",
          "Maintenance": "Mantenimiento",
          "Gallery": "Galería",
          "Gallery Grid": "Cuadrícula de la galería",
          "Gallery Grid  Desc": "Desc. de cuadrícula de galería",
          "Masonry Gallery": "Galería de albañilería",
          "Masonry With Desc": "Albañilería Con Desc",
          "Hover Effect": "Efecto de desplazamiento",
          "Blog": "Blog",
          "Blog Details": "Detalles del blog",
          "Blog Single": "Blog Sencillo",
          "Add Post": "Añadir Publicación",
          "Job Search": "Búsqueda de trabajo",
          "Cards View": "Vista de tarjetas",
          "List View": "Vista de la lista",
          "Job Details": "Detalles del trabajo",
          "Apply": "Aplicar",
          "Learning": "Aprendiendo",
          "Learning List": "Lista de aprendizaje",
          "Detail Course": "Curso detallado",
          "Maps": "Mapas",
          "Google Maps": "Google Mapas",
          "Editor": "Editor",
          "CK  Editor": "Editor de CK",
          "MDE Editor": "Editor MDE ",
          "ACE code editor": "Editor de código ACE",
          "FAQ": "PREGUNTAS MÁS FRECUENTES",
          "knowledgebase": "base de conocimientos",
          "KnowledgeCategory": "Categoría de conocimientos",
          "KnowledgeDetail": "Detalle del conocimiento",
          "sub": "sub",
        },
      },
      fr: {
        translations: {
          "DashBoard": "Tableau de bord",
          "Deafult": "Défaut",
          "Ecommerce": "Commerce électronique",
          "Widgets": "Widget",
          "General": "Général",
          "Chart": "Graphique",
          "Project": "Projet",
          "Project List": "Liste de projets",
          "Create New": "Créer un nouveau",
          "Product": "Produit",
          "Product Page": "Page produit",
          "Product List": "Liste de produits",
          "Add Product": "Ajouter un produit",
          "Payment Detail": "Détail du paiement",
          "Order History": "Historique des commandes",
          "Pricing": "Prix",
          "Invoice": "Facture",
          "Cart": "Chariot",
          "Checkout": "Vérifier",
          "Whishlist": "Liste de souhaits",
          "Users": "Utilisateurs",
          "Users Profile": "Profil d'utilisateur",
          "Users Edit": "Utilisateurs Modifier",
          "Users Cards": "Cartes d'utilisateurs",
          "Calender": "Calendrier",
          "Chat": "Discuter",
          "Chat App": "Application de chat",
          "Video App": "Application vidéo",
          "Email": "E-mail",
          "Mail Inbox": "Boîte de réception",
          "Read Mail": "Lire le courrier",
          "Compose": "Composer",
          "File Manager": "Gestionnaire de fichiers",
          "Kanban Board": "Tableau Kanban",
          "Bookmark": "Signet",
          "Task": "Tâche",
          "Social App": "Application sociale",
          "Contacts": "Contacts",
          "To-Do": "Faire",
          "Ui-Kits": "Ui-Kits",
          "Typography": "Typographie",
          "Avatars": "Avatar",
          "Helper-Classes": "Classes d'assistance",
          "Grid": "Grille",
          "Tag & Pills": "Étiquette et pilules",
          "Progress": "Progrès",
          "Modal": "Modal",
          "Alert": "Alerte",
          "Popover": "Popover",
          "Tooltip": "Info-bulle",
          "Spinners": "Tourneurs",
          "Dropdown": "Menu déroulant",
          "Accordion": "Accordéon",
          "Tabs": "Onglets",
          "Bootstrap Tabs": "Onglets d'amorçage",
          "Line Tabs": "Onglets de ligne",
          "Shadow": "Ombre",
          "List": "Liste",
          "Bonus Ui": "Interface utilisateur bonus",
          "Scrollable": "Déroulable",
          "Tree": "Arbre",
          "Rating": "Notation",
          "Dropzone": "Zone de largage",
          "Tour": "Visite",
          "SweetAlert": "SweetAlert",
          "Ribbons": "Rubans",
          "Pagination": "Pagination",
          "Breadcrumb": "Miette de pain",
          "Range Slider": "Curseur de plage",
          "Image Cropper": "Recadrage d'image",
          "Sticky": "Collant",
          "Drag and Drop": "Glisser déposer",
          "Upload": "Télécharger",
          "Basic Card": "Carte de base",
          "Draggable Card": "Carte Déplaçable",
          "Timeline": "Calendrier",
          "Icons": "Icônes",
          "Flag Icon": "Icône de drapeau",
          "Fontawesome Icon": "Icône Fontawesome",
          "Ico Icon": "Icône Icon",
          "Themify Icon": "Icône Themify",
          "Feather Icon": "Icône Plume",
          "Whether Icon": "Icône si",
          "Buttons": "Boutons",
          "Default Style": "Style par défaut",
          "Button Group": "Groupe de boutons",
          "Charts": "Graphiques",
          "Apex Chart": "Tableau des sommets",
          "Google Chart": "Graphique Google",
          "Chartjs": "Graphiques",
          "Chartist": "Chartiste",
          "Forms": "Formes",
          "Form Controls": "Contrôles de formulaire",
          "Form Validation": "Validation du formulaire",
          "Basic Input": "Entrée de base",
          "Checkbox & Radio": "Case à cocher et radio",
          "Input Groups": "Groupes d'entrée",
          "Mega Option": "Méga Option",
          "Form Widgets": "Widgets de formulaire",
          "Datepicker": "Sélecteur de date",
          "Typeahead": "Typehead",
          "rangepicker": "rangepicker",
          "Touchspin": "Touchspin",
          "Select2": "Select2",
          "Switch": "Changer",
          "Clipboard": "Presse-papiers",
          "Form Default": "Formulaire par défaut",
          "Form Wizard": "Assistant Formulaire",
          "Tables": "Les tables",
          "Reactstrap Table": "Tableau Reactstrap",
          "Basic Table": "Table de base",
          "Sizing Table": "Tableau des tailles",
          "Border Table": "Tableau Bordure",
          "Styling Table": "Tableau de style",
          "Data Tables": "Tableaux de données",
          "Sample Page": "Page d'exemple",
          "Others": "Autres",
          "Coming Soon": "Autres",
          "Coming Sample": "À venir",
          "Coming with Bg-video": "Échantillon à venir",
          "Coming with Bg-Image": "Venir avec Bg-vidéo",
          "Authentication": "Authentification",
          "Login Simple": "Connexion simple",
          "Login With bg image": "Connexion avec l'image deux",
          "Login with image two": "Connexion avec l'image deux 2",
          "Login with validation": "Connexion avec validation",
          "Login with tooltip": "Connexion avec info-bulle",
          "Login with sweetaleart": "Connexion avec sweetalart",
          "Register Simple": "Enregistrer Simple",
          "Register with Bg image": "S'inscrire avec l'image Bg",
          "Register with Bg Video": "S'inscrire avec Bg Video",
          "Unlock User": "Déverrouiller l'utilisateur",
          "Forgot Password": "Mot de passe oublié",
          "Create Passward": "Créer un mot de passe",
          "Maintenance": "Entretien",
          "Gallery": "Galerie",
          "Gallery Grid": "Grille de la galerie",
          "Gallery Grid  Desc": "Galerie Grid Desc",
          "Masonry Gallery": "Galerie de la maçonnerie",
          "Masonry With Desc": "Maçonnerie Avec Desc",
          "Hover Effect": "Effet de survol",
          "Blog": "Blog",
          "Blog Details": "Détails du blog",
          "Blog Single": "Blog Unique",
          "Add Post": "Ajouter un article",
          "Job Search": "Recherche d'emploi",
          "Cards View": "Vue Cartes",
          "List View": "Vue Liste",
          "Job Details": "Détails du poste",
          "Apply": "Appliquer",
          "Learning": "Apprentissage",
          "Learning List": "Liste d'apprentissage",
          "Detail Course": "Cours détaillé",
          "Maps": "Plans",
          "Google Maps": "Google ",
          "Editor": "Éditeur",
          "CK  Editor": "Éditeur CK",
          "MDE Editor": " Éditeur MDE ",
          "ACE code editor": "Éditeur de code ACE",
          "FAQ": "faq",
          "knowledgebase": "base de connaissances",
          "KnowledgeCategory": "ConnaissanceCatégorie",
          "KnowledgeDetail": "Détail des connaissances",
          "sub": "sous",
        },
      },
      pt: {
        translations: {
          "DashBoard": "Painel",
          "Deafult": "Pardo",
          "Ecommerce": "Comércio eletrônico",
          "Widgets": "Widgets",
          "General": "Em geral",
          "Chart": "Gráfico",
          "Project": "Projeto",
          "Project List": "Lista de Projetos",
          "Create New": "Crie um novo",
          "Product": "Produtos",
          "Product Page": "Página do produto",
          "Product List": "Lista de produtos",
          "Add Product": "Adicionar produto",
          "Payment Detail": "Adicionar produto",
          "Order History": "Histórico de pedidos",
          "Pricing": "Preços",
          "Invoice": "Fatura",
          "Cart": "Carrinho",
          "Checkout": "Confira",
          "Whishlist": "Lista de desejos",
          "Users": "Usuários",
          "Users Profile": "Perfil dos Usuários",
          "Users Edit": "Editar usuários",
          "Users Cards": "Cartões de Utilizador",
          "Calender": "Calendário",
          "Chat": "Bater papo",
          "Chat App": "Aplicativo de bate-papo",
          "Video App": "Aplicativo de vídeo",
          "Email": "E-mail",
          "Mail Inbox": "Caixa de entrada de correio",
          "Read Mail": "Ler E-mail",
          "Compose": "Escrever",
          "File Manager": "Gerenciador de arquivos",
          "Kanban Board": "Marca páginas",
          "Bookmark": "Marcador",
          "Task": "Tarefa",
          "Social App": "Aplicativo Social",
          "Contacts": "Contatos",
          "To-Do": "Pendência",
          "Ui-Kits": "UI-Kits",
          "Typography": "Tipografia",
          "Avatars": "Avatares",
          "Helper-Classes": "Classes auxiliares",
          "Grid": "Grade",
          "Tag & Pills": "Tag & Comprimidos",
          "Progress": "Progresso",
          "Modal": "Modo",
          "Alert": "Alerta",
          "Popover": "Dar um pulo",
          "Tooltip": "Dica",
          "Spinners": "Spinners",
          "Dropdown": "Menu suspenso",
          "Accordion": "Acordeão",
          "Tabs": "Guias",
          "Bootstrap Tabs": "Guias de inicialização",
          "Line Tabs": "Guias de linha",
          "Shadow": "Sombra",
          "List": "Lista",
          "Bonus Ui": "Interface Bônus",
          "Scrollable": "Scrollable",
          "Tree": "Árvore",
          "Rating": "Avaliação",
          "Dropzone": "Ponto de largada",
          "Tour": "Percorrer",
          "SweetAlert": "SweetAlert",
          "Ribbons": "Fitas",
          "Pagination": "Paginação",
          "Breadcrumb": "Migalhas de pão",
          "Range Slider": "Intervalo deslizante",
          "Image Cropper": "Recorte de imagem",
          "Sticky": "Pegajoso",
          "Drag and Drop": "Arrastar e soltar",
          "Upload": "Carregar",
          "Basic Card": "Cartão Básico",
          "Draggable Card": "Cartão Arrastável",
          "Timeline": "Linha do tempo",
          "Icons": "Ícones",
          "Flag Icon": "Ícone de bandeira",
          "Fontawesome Icon": "Ícone Fontawesome",
          "Ico Icon": "Ícone Ico",
          "Themify Icon": "Ícone Themify",
          "Feather Icon": "Ícone de Pena",
          "Whether Icon": "Se ícone",
          "Buttons": "Botões",
          "Default Style": "Estilo Padrão",
          "Button Group": "Grupo de botões",
          "Charts": "Gráficos",
          "Apex Chart": "Gráfico Apex",
          "Google Chart": "Gráfico do Google",
          "Chartjs": "Gráficos",
          "Chartist": "Cartista",
          "Forms": "Formulários",
          "Form Controls": "Controles de formulário",
          "Form Validation": "Validação do formulário",
          "Basic Input": "Entrada básica",
          "Checkbox & Radio": "Caixa de seleção e rádio",
          "Input Groups": "Grupos de entrada",
          "Mega Option": "Opção Mega",
          "Form Widgets": "Widgets de formulário",
          "Datepicker": "Selecionador de datas",
          "Typeahead": "Digitar à frente",
          "rangepicker": "rangepicker",
          "Touchspin": "Touchspin",
          "Select2": "Selecione2",
          "Switch": "Trocar",
          "Clipboard": "Prancheta",
          "Form Default": "Formulário padrão",
          "Form Wizard": "Assistente de formulário",
          "Tables": "Tabelas",
          "Reactstrap Table": "Tabela Reactstrap",
          "Basic Table": "Tabela Básica",
          "Sizing Table": "Tabela de Dimensionamento",
          "Border Table": "Tabela de borda",
          "Styling Table": "Mesa de estilo",
          "Data Tables": "Tabelas de dados",
          "Sample Page": "Página de exemplo",
          "Others": "Outros",
          "Coming Soon": "Em breve",
          "Coming Sample": "Amostra em breve",
          "Coming with Bg-video": "Coming with Bg-video",
          "Coming with Bg-Image": "Vindo com Bg-Image",
          "Authentication": "Autenticação",
          "Login Simple": "Login Simples",
          "Login With bg image": "Entrar com imagem bg",
          "Login with image two": "Login com a imagem dois",
          "Login with validation": "Login com validação",
          "Login with tooltip": "Login com dica de ferramenta",
          "Login with sweetaleart": "Entrar com sweetaleart",
          "Register Simple": "Cadastro Simples",
          "Register with Bg image": "Registrar com imagem Bg",
          "Register with Bg Video": "Registre-se no Bg Video",
          "Unlock User": "Desbloquear usuário",
          "Forgot Password": "Esqueceu sua senha",
          "Create Passward": "Criar senha",
          "Maintenance": "Manutenção",
          "Gallery": "Galeria",
          "Gallery Grid": "Grade da Galeria",
          "Gallery Grid  Desc": "Galeria Grade Desc",
          "Masonry Gallery": "Galeria de Alvenaria",
          "Masonry With Desc": "Alvenaria com Desc",
          "Hover Effect": "Efeito de foco",
          "Blog": "Blog",
          "Blog Details": "Detalhes do blog",
          "Blog Single": "Blog Único",
          "Add Post": "Adicionar postagem",
          "Job Search": "Procura de emprego",
          "Cards View": "Visualização de cartões",
          "List View": "Exibição de lista",
          "Job Details": "Detalhes do trabalho",
          "Apply": "Aplicar",
          "Learning": "Aprendizado",
          "Learning List": "Lista de Aprendizagem",
          "Detail Course": "Curso Detalhado",
          "Maps": "Mapas",
          "Google Maps": "Google Mapas",
          "Editor": "",
          "CK  Editor": "CK Editor",
          "MDE Editor": "MDE Editor",
          "ACE code editor": "ACE code editor",
          "FAQ": "PERGUNTAS FREQUENTES",
          "knowledgebase": "base de conhecimento",
          "KnowledgeCategory": "Categoria de Conhecimento",
          "KnowledgeDetail": "Detalhes do Conhecimento",
          "sub": "sub",
        },
      },
    },
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
